import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classlist),
    style: _normalizeStyle({ height: _ctx.height, '--spoiler-time': `${_ctx.time}ms` }),
    onTransitionend: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onTransitionEnd && _ctx.onTransitionEnd(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 38))
}